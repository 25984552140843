const isProd = process.env.NODE_ENV === 'production';

export default {
    IsProd(){
        return isProd;
    },
    // get Version(){

    // }

    get URL() {
        switch (location.hostname.toLowerCase()) {
          case "localhost":
            // return "http://localhost:8064/api";
            return "https://apib.yacalifica.me/api";
          case "rb.yacalifica.me":
            // return "http://localhost:8064/api";
            return "https://apib.yacalifica.me/api";

            default:
                return "https://api.yacalifica.me/api";
        }
      },

}