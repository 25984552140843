import Axios from "axios";
import BaseService from "./base.service";
import LiAuth from "@/api-services/liauth.service";
const isProd = process.env.NODE_ENV === "production";

import moment from "moment";
moment.locale("es-mx");


export default {
  get URL() {
    return BaseService.URL;
  },

  ObtenErrorMsg(error) {
    var MsgError = "";
    // console.debug(error);
    if (error.response == undefined) {
      MsgError = "Intente nuevamente en un par de minutos";
    } else
      switch (error.response.status) {
        case 400:
          {
            if (error.response.data) {
              if (error.response.data.title) MsgError = error.response.data.title;
              else MsgError = error.response.data;
            } else MsgError = "Error desconocido en el servidor, contacte a soporte técnico";
          }
          break;
        case 401:
          //No borraré la sesión actual para validar
          //localStorage.sesion = ""; // error.response.data;
          MsgError = "Sesión no válida, intente iniciar sesión nuevamente";
          setTimeout(() => {
            window.location.href = "/login";
          }, 3000);
          break;
        case 402:
          MsgError = "Se requiere pago";
          break;
        case 404:
          MsgError = "No encontrado";
          break;
        case 409:
          MsgError = "Ya existe el registro, buscalo tambien en inactivos";
          break;
        case 500:
          MsgError = "Error en servidor, contacte a soporte con los pasos que siguio";
          break;
        default:
          if (
            error.response === undefined ||
            error.response.data === undefined ||
            error.response.data.error === undefined
          ) {
            console.error(error);
            MsgError = "Intente nuevamente";
          } else {
            console.error(error.response.data.error);
            MsgError = error.response.data.error.mensaje;
          }
          break;
      }

    return MsgError;
  },

  get(Vista, URL, Opciones, OnCorrecto, OnErroneo, OnFinalizado) {
    const Comando = "GET";

    this.SeguimientoIniciar(Vista, URL, Comando);
    return this.getBase(
      Vista,
      URL,
      Opciones,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },

  getBase(Vista, URL, Opciones, OnCorrecto, OnErroneo, OnFinalizado) {
    const Comando = "GET";

    Opciones =null
    //  console.debug(Opciones);
    Axios.get(URL, Opciones)
      .then((response) => {
        if (OnCorrecto != undefined) OnCorrecto(response);
      })
      .catch((error) => {
        this.SeguimientoError(Vista, OnErroneo, URL, Comando, error);
      })
      .finally(() => {
        this.SeguimientoFinalizar(Vista, URL, Comando);
        if (OnFinalizado != undefined) OnFinalizado();
      });
  },

  post(Vista, URL, Datos, Opciones, OnCorrecto, OnErroneo, OnFinalizado) {
    const Comando = "POST";
    this.SeguimientoIniciar(Vista, URL, Comando);
    return this.postBase(
      Vista,
      URL,
      Datos,
      Opciones,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  postBase(Vista, URL, Datos, Opciones, OnCorrecto, OnErroneo, OnFinalizado) {
    const Comando = "POST";

    Opciones = this.ObtenOpciones(Opciones);

    Axios.post(URL, Datos, Opciones)
      .then((response) => {
        if (OnCorrecto != undefined) OnCorrecto(response);
      })
      .catch((error) => {
        this.SeguimientoError(
          Vista,
          OnErroneo,
          URL,
          { Comando: Comando, Datos: Datos, Opciones: Opciones },
          error
        );
      })
      .finally(() => {
        this.SeguimientoFinalizar(Vista, URL, Comando);
        if (OnFinalizado != undefined) OnFinalizado();
      });
  },

  SeguimientoIniciar(Vista, URL, Comando) {
    if (!isProd) {
      console.debug(
        JSON.stringify({
          Estado: "Iniciando",
          name: Vista != null ? Vista.$options.name : null,
          URL: URL,
          Comando: Comando,
        })
      );
    }
  },
  SeguimientoError(Vista, OnErroneo, URL, Comando, Error) {
    var status = 0;
    //  console.debug(Comando);
    console.error(Error);
    var MsgError = this.ObtenErrorMsg(Error);

    if (OnErroneo != undefined) {
      var R = OnErroneo(MsgError, Error);
      // console.debug(R);
      if (R != null) MsgError = R;
    }

    if (Error.response != undefined) status = Error.response.status;

    return status;
  },
  SeguimientoFinalizar(Vista, URL, Comando, Error) {
    if (!isProd) {
      console.debug(
        JSON.stringify({
          Estado: "Finalizado",
          name: Vista != null ? Vista.$options.name : null,
          URL: URL,
          Comando: Comando,
        })
      );
    }
    if (Error != undefined) {
        return Error
    }
  },
  ObtenOpciones(Opciones) {
    if (Opciones == null) {
      Opciones = LiAuth.header();
    } else {
      if (Opciones.headers == null) {
        Opciones.headers = LiAuth.header().headers;
      } else {
        /*      var LH = LiAuth.header();
              var Headers = LH.headers;
              var Propiedades = Object.keys(Headers);
              console.debug(Headers);*/
        var Headers = LiAuth.header().headers;
        for (var name in Headers) {
          //  alert(name);
          var value = Headers[name];
          //   console.debug(value);
          Opciones.headers[name] = value;
          //alert(value);
        }
        /*
                for (var i = 0; i < Propiedades.length; i++) {
                  console.debug(Object[Propiedades[i]]);
                 // Opciones.headers.push(Headers[i]);
                }*/
      }
    }

    //console.debug(Opciones);
    return Opciones;
  },
};
