<template>
  <footer class="row">
    <div class="col-md-6 col-sm-8">
      <a href="https://www.yacalifica.me/" target="_blank" rel="noopener" class="nav-link">
        YaCalifica.me
      </a>
    </div>
    <div class="col-md-6 col-sm-8">
      <div class="copyright text-wrap" @click="OnClick">
        &copy; {{ year }}, creado con
        <font-awesome-icon :icon="['fas', 'heart']" style="color: #63E6BE;" /> para valorar.
      </div>
    </div>
  </footer>
</template>
<script>
import Base from "@/api-services/base";
import Mantenimiento from "@/api-services/mantenimiento";
import Rmantenimiento from "@/api-services/Rmantenimiento";


export default {
  data() {
    return {
      year: new Date().getFullYear(),
      Datos: "",
      cargando: false,
    };
  },
  mounted() {
  },
  methods: {
    OnClick() {
      if (this.cargando) return;
      let version = null
      Rmantenimiento.Version(this,(response) => {
        version = response.data
        Mantenimiento.Version(this, (response) => {
          this.$swal({
            iconColor: "#2ECD89",
            title: "Versión",
            html: `
              Api: <b>${response.data}</b> <br>
              RYCv: <b>${version}</b> <br>
              Fuente: <b>${this.getApi()}</b>`,
              iconHtml: '<i class="fas fa-seedling" style="color: #2ECD89; boder: none" ></i>',
            showCancelButton: false,
            confirmButtonText: "Ok",
            confirmButtonColor: "#63E6BE",
          });
        });
      })
      /*
        if (!BaseService.IsProd())s
          this.Datos = `${localStorage.suscripcionID}/${localStorage.sesion}`*/
    },
    getApi() {
      let urls = Base.URL
      let url = new URL(urls)
      return url.host.split(".")[0]
    }
  },
};
</script>
<style lang="scss"></style>