
import Base from '@/api-services/base';
import RyaCalificaMe from './RyaCalifica.me.service.js';
const BaseURL = `${RyaCalificaMe.URL}/Mantenimiento`;

export default {
  Version(Vista,OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.get(Vista, `${BaseURL}/Version`, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
}
