/** @format */

const isProd = process.env.NODE_ENV === "production";
export default {
  IsProd() {
    // return true;
    return isProd;
  },

  get URL() {
    var lURL = localStorage.ApiYaCalificameURL;
    if (lURL) return lURL;

    switch (location.hostname.toLowerCase()) {
      case "localhost":
        // return "http://localhost:8064/api";
        return "https://rb.yacalifica.me/api";
      case "r.yacalifica.me":
        return "https://rb.yacalifica.me/api";
      case "rb.yacalifica.me":
        return "https://rb.yacalifica.me/api";
    }
    lURL = this.IsProd() ? "https://r.YaCalifica.me/api" : "https://rb.YaCalifica.me/api"; // "https://debug.apiv6.eclock.com.mx/api")
    //  localStorage.URL = lURL;

    return lURL;
  },
};
