<template>
  <div  style="background-color: #2dcf94; height: 15vh;" class="w-100">
    <div style="height: 100%;" class="w-100 d-flex justify-content-center align-middle my-auto">
      <img style="margin-top: 60px;"  src="img/Logos/LogoYaCalificameblancocompleto.png" width="200" height="50" />
    </div>
  </div>
  <router-view />
  <content-footer />
</template>

<script>
import ContentFooter from './views/ContentFooter.vue';
export default {
  components: { ContentFooter },
  mounted() {
    let Ubicacion = window.location.href;
    Ubicacion = Ubicacion.replace("index.html", "");
    let Actualurl = window.location.pathname;
    if (Actualurl !== undefined && Actualurl !== "/") {
      let Recortar = Ubicacion.indexOf("#");
      let NuevaUbicacion = Ubicacion.substring(0, Recortar);
      NuevaUbicacion = NuevaUbicacion.replace(Actualurl, "/#" + Actualurl);
      if (this.RedirigeHttps(NuevaUbicacion, true)) return;
    } else {
      if (this.RedirigeHttps(Ubicacion)) return;
    }
  },

  methods: {
    RedirigeHttps(Ubicacion, Forzar) {
      let NUrl = `${Ubicacion}`;
      if (NUrl.indexOf("localhost") < 0 && NUrl.indexOf("10.") < 0) {
        NUrl = NUrl.replace("http://", "https://");
      }
      if (NUrl != Ubicacion || (Forzar != null && Forzar != false)) {
        window.location.href = NUrl;
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
