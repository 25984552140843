
//const isProd = process.env.NODE_ENV === "production";
/*
import Base from '@/api-services/base'

//const BaseURL = 'http://localhost:50598';
const USUARIOS = `${Base.URL}/usuarios`;
const SESIONES = `${Base.URL}/sesiones`;*/
// var config = {
//     headers: {
//         'Content-type': 'application/json; charset=utf-8',
//         'Access-Control-Allow-Headers': 'content-type',
//     }
// };

export default {

    header() {
        //console.debug(localStorage.sesion);
        var config = {
            headers: {
            // 'Authorization': localStorage.sesion,
            // 'ApiKey':process.env.VUE_APP_API_KEY
            }
        };
        return config;
    },
    headerMultipart(onUploadProgress) {
        //console.debug(localStorage.sesion);
        var config = {
            headers: {
                // 'Authorization': localStorage.sesion,
            'Content-Type': 'multipart/form-data',
            // 'ApiKey': process.env.VUE_APP_API_KEY
            }
        };
        if (onUploadProgress != undefined)
            config.onUploadProgress = onUploadProgress;
        return config;
    },
  /*  usuario_nuevo(data) {
        return Axios.post(USUARIOS, data);
    },
    sesion_nueva(data) {
        console.debug(SESIONES);
        return Axios.post(SESIONES, data, config);
    },

*/
    
};
